<template>
  <TTDialog :title="`${title} Download`" :active="active" v-on:close="close">
    <p>
      Click below on one of the available download options. Do not leave or
      reload the page.
    </p>
    <v-row justify="center">
      <v-col>
        <FormulateInput
          type="button"
          name="Current Page"
          help="Quickest download option."
          @click="selectCSV('page')"
        />
      </v-col>
      <v-col>
        <FormulateInput
          type="submit"
          name="All Data"
          help="May take several minutes to download."
          @click="selectCSV('all')"
          :loading="loading"
          :disabled="isClicked"
        />
      </v-col>
    </v-row>
    <!-- <v-row justify="center"> -->
    <!-- <v-col> -->

    <div class="row" v-if="selection && !loading">
      <div class="col flex center full">
        <JsonCSV :data="jsonData" :name="title + '.csv'">
          <FormulateInput type="button" name="Download"
        /></JsonCSV>
      </div>
    </div>
  </TTDialog>
</template>

<script>
import TTDialog from "@/components/TTDialog.vue";
import JsonCSV from "vue-json-csv";
import DiyoboButton from "../DiyoboButton.vue";

export default {
  name: "csv-selector",
  components: {
    TTDialog,
    JsonCSV,
    DiyoboButton,
  },
  props: {
    jsonData: Array,
    estimateTime: {
      type: String,
      default: "5 mins",
    },
    noOfEntries: {
      type: String,
      default: "1000",
    },
    title: {
      type: String,
      default: "CSV Download",
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      active: false,
      collection: null,
      selection: null,
      isClicked: false,
    };
  },
  methods: {
    selectCSV(data) {
      this.selection = data;
      this.$emit("download", data);
      if (data === "all") this.isClicked = true;
    },
    open() {
      this.isClicked = false;
      this.selection = null;

      setTimeout(() => {
        this.active = true;
      }, 250);
    },
    close() {
      this.active = false;
    },
  },
};
</script>

<style lang="less" scoped>
.detail {
  display: flex;
  flex-direction: column;
}
.formulate-input-wrapper {
  .formulate-input[data-classification="button"] button,
  .formulate-input[data-classification="submit"] button {
    width: 100%;
  }
}
::v-deep button {
  width: 100%;
}
</style>
