<template>
  <div class="data-table">
    <csv-selector
      ref="csvSelector"
      :title="title"
      @download="downloadCsv"
      :jsonData="jsonData"
      :loading="jsonLoader"
    />
    <filter-selector ref="filterSelector" :selectedFilter="selectedFilter" />
    <div v-if="title" class="title">
      <div class="left">
        <h4 :class="financials ? 'financials-h4' : null">{{ title }}</h4>
        <span v-if="subtitle">{{ subtitle }}</span>
        <div class="filter">
          <FormulateInput
            type="select"
            :options="filterOptions"
            placeholder="Add Filter"
            @input="addFilter"
          />
        </div>

        <!-- <button
          class="filter-button"
          type="button"
          @click="$refs.filterSelector.open()"
        >
          <font-awesome-icon class="icon" icon="filter" />
          Filter
        </button> -->
      </div>

      <div class="right">
        <!-- <JsonCSV :data="jsonData" :name="title.toLowerCase() + '.csv'">
          <button
            :class="financials ? 'financials-csv' : 'csv-download-button'"
            type="button"
          >
            <font-awesome-icon class="icon" icon="file" />
            Download Detailed CSV
          </button>
        </JsonCSV> -->
        <button
          :class="financials ? 'financials-csv' : 'csv-download-button'"
          type="button"
          @click="csvSelectionModal"
        >
          <font-awesome-icon class="icon" icon="file" />
          Download Detailed CSV
        </button>
      </div>
    </div>
    <div class="content">
      <div class="top-bar" v-if="useSearchAndPagination">
        <div class="search" v-if="showSearch">
          <FormulateInput
            type="text"
            ignored
            :placeholder="searchPlaceholder"
            v-model="search"
          />
        </div>
        <slot name="header"></slot>

        <div class="show-more">
          <small>Show more entries</small>
          <FormulateInput
            type="select"
            style="width: 100px"
            :options="pageSizes"
            v-model="pageSize"
          />
        </div>
      </div>
      <!-- <slot name="header"></slot> -->
      <v-data-table
        class="elevation-2 row-pointer mb-16"
        v-model="selected"
        dark
        :dense="dense"
        :class="{ compact }"
        :headers="headers"
        :items="items"
        :server-items-length="itemsCount"
        :footer-props="footerProps"
        :page="page"
        :hide-default-footer="!useSearchAndPagination"
        :items-per-page="itemsPerPage"
        :loading="loading"
        :search="search"
        :show-select="showSelect"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        item-key="_id"
        :expanded.sync="expanded"
        @click:row="onClickRow"
        @update:page="onPagination"
        @update:sort-by="onSortChange"
        @update:sort-desc="onSortChange"
      >
        <template v-if="customRow" v-slot:item="attrs">
          <slot name="item" v-bind="attrs"></slot>
        </template>
        <template v-slot:expanded-item="attrs">
          <slot name="expanded-item" v-bind="attrs"></slot>
        </template>
        <template
          v-for="column in customColumns"
          v-slot:[`item.${column}`]="attrs"
        >
          <slot :name="`item.${column}`" v-bind="attrs"></slot>
        </template>
        <!-- custom formatted fields -->
        <template
          v-for="(header, index) in headers.filter((h) => h.format)"
          v-slot:[`item.${header.value}`]="{ item }"
        >
          <span v-if="header.format === 'currency'" :key="index">
            {{ formatMoney(item[header.value] || "0") }}
          </span>
          <span
            v-if="item[header.value] && header.format === 'date'"
            :key="index"
          >
            {{ formatDate(item[header.value]) }}
          </span>
        </template>
        <template v-slot:[`body.append`]>
          <tr
            class="totals"
            v-if="totals && !loading"
            style="font-weight: bold"
          >
            <td>TOTALS</td>
            <td
              v-for="({ value, format }, index) in headers.slice(1)"
              :key="index"
            >
              <template v-if="totals[value]">
                {{
                  format == "currency"
                    ? formatMoney(totals[value])
                    : totals[value]
                }}
              </template>
            </td>
          </tr>
        </template>

        <template v-slot:[`body.append`]>
          <tr
            class="totals"
            v-if="totalCount && !loading"
            style="font-weight: bold"
          >
            <td>TOTAL COUNT: {{ itemsCount }}</td>
          </tr>
        </template>
      </v-data-table>
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script>
import { library } from "@fortawesome/fontawesome-svg-core";
import { faFile, faFilter } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import dayjs from "dayjs";
import AdvancedFormat from "dayjs/plugin/advancedFormat";
import Timezones from "dayjs/plugin/timezone";
import UTC from "dayjs/plugin/utc";
import debounce from "debounce";
import JsonCSV from "vue-json-csv";
import CsvSelector from "./modals/CsvSelector.vue";
import { convert } from "../helpers/currencyConversion";
import FilterSelector from "./modals/FilterSelector.vue";

dayjs.extend(AdvancedFormat);
dayjs.extend(UTC);
dayjs.extend(Timezones);

library.add(faFile, faFilter);

export default {
  name: "data-table",
  components: {
    FontAwesomeIcon,
    JsonCSV,
    CsvSelector,
    FilterSelector,
  },
  props: {
    searchPlaceholder: { type: String, default: "Search..." },
    dense: { type: Boolean, default: true },
    endpoint: String,
    args: Object,
    params: Object,
    title: String,
    subtitle: String,
    headers: Array,
    tabs: Array,
    customRow: Boolean,
    customColumns: Array,
    showSelect: Boolean,
    compact: Boolean,
    csvMapper: Function,
    financials: Boolean,
    expand: {
      type: Boolean,
      default: false,
    },
    useSearchAndPagination: {
      type: Boolean,
      default: true,
    },
    showSearch: {
      type: Boolean,
      default: true,
    },
    csvLink: {
      type: String,
      default: null,
    },
    totalCount: {
      type: Boolean,
      default: false,
    },
    csvSize: {
      type: Number,
      default: 500,
    },
  },
  data() {
    return {
      items: [],
      itemsCount: 0,
      totals: null,
      selected: [],
      tab: 0,
      page: 1,
      pageSize: "10",
      pageSizes: [
        { label: "10", value: "10" },
        { label: "25", value: "25" },
        { label: "50", value: "50" },
        { label: "100", value: "100" },
      ],
      currentState: "ALL",
      search: "",
      sortBy: [],
      sortDesc: [],
      jsonData: [],
      loading: true,
      csvData: [],
      jsonLoader: false,

      // Filter Options
      filterOptions: [
        { label: "First Name", value: "first_name", type: "string" },
        { label: "Last Name", value: "last_name", type: "string" },
        { label: "Email", value: "email", type: "string" },
        { label: "Amount", value: "amount", type: "number" },
        { label: "Quantity", value: "quantity", type: "number" },
      ],
      selectedFilter: null,
    };
  },
  computed: {
    expanded() {
      return this.expand === true ? this.items : [];
    },
    query() {
      return {
        page: this.page,
        pageSize: this.itemsPerPage,
        state: this.currentState,
        search: this.search || undefined,
        sortBy: this.sortBy ? this.sortBy[0] : undefined,
        sortDesc: this.sortDesc ? this.sortDesc[0] : undefined,

        ...(this.params || {}),
      };
    },
    footerProps() {
      if (this.useSearchAndPagination) {
        return {
          "show-current-page": true,
          "show-first-last-page": true,
          "page-text": "SHOWING {0} to {1} of {2} ENTRIES",
        };
      }
      return {
        disabled: true,
      };
    },
    endpointURL() {
      let endpoint = this.endpoint;
      if (this.args) {
        Object.entries(this.args).forEach(([arg, value]) => {
          endpoint = endpoint.replace(`:${arg}`, value);
        });
      }

      return endpoint;
    },
    csvEndpointURL() {
      let endpoint = this.csvLink;
      if (this.args) {
        Object.entries(this.args).forEach(([arg, value]) => {
          endpoint = endpoint.replace(`:${arg}`, value);
        });
      }

      return endpoint;
    },
    itemsPerPage() {
      return Number(this.pageSize);
    },
  },
  watch: {
    search() {
      this.loading = true;
      this.refetchData();
    },
    params() {
      this.loading = true;
      this.refetchData();
    },
    selected(val) {
      this.$emit("selected", val);
    },
    pageSize() {
      this.onPageSizeChange();
    },
  },

  methods: {
    addFilter(val) {
      let filter = this.filterOptions.find((item) => {
        if (item.value === val) {
          return item;
        }
      });
      this.selectedFilter = filter;
      this.$refs.filterSelector.open();
    },
    formatMoney(money) {
      return convert(1, parseFloat(money), "$");
    },
    formatDate(date) {
      if (date.$date) {
        let newDate = date.$date.$numberLong;
        return dayjs(+newDate).format("MMM DD, YYYY, hh:mm A");
      } else {
        let newDate = date;
        return dayjs(newDate).format("MMM DD, YYYY");
      }
    },
    async downloadCsv(kind) {
      if (kind === "all") {
        if (this.itemsCount > 9999 && this.csvLink) {
          this.$axios.get(this.csvEndpointURL).then((resp) => {});
          this.$toast(
            "Your CSV will be sent to your email within a few minutes."
          );
          this.$refs.csvSelector.close();
          return;
        } else {
          this.jsonLoader = true;
          let itemsFetched = 0;
          let page = 1;
          while (itemsFetched < this.itemsCount) {
            await this.$axios
              .get(this.endpointURL, {
                params: {
                  page,
                  pageSize: this.csvSize,
                  csv: true,
                  ...(this.params || {}),
                },
              })
              .then(({ data }) => {
                data.data.items.forEach((item) => {
                  this.csvData.push(item);
                });
                itemsFetched += this.csvSize;
                page++;
              });
          }

          if (this.csvMapper) {
            this.jsonData = await this.csvMapper(this.csvData);
          } else {
            this.jsonData = this.csvData;
          }
        }
        this.jsonLoader = false;
      } else {
        // if (this.csvMapper) {
        //   this.jsonData = await this.csvMapper(this.jsonData);
        // } else {
        //   this.jsonData = this.jsonData;
        // }
      }
    },
    hasValueNested(obj, key) {
      return key.split(".").every(function(x) {
        if (typeof obj != "object" || obj === null || !x in obj) return false;
        obj = obj[x];
        return true;
      });
    },
    csvSelectionModal() {
      this.$refs.csvSelector.open();
    },
    refetchData: debounce(async function(page = 1) {
      this.page = page;
      this.fetchData(page);
    }, 200),
    onPagination(page) {
      this.loading = true;
      this.refetchData(page);
    },
    onSortChange() {
      this.loading = true;
      this.refetchData();
    },
    onClickRow(item) {
      const index = this.items.indexOf(item);
      this.$emit("edit", { item, index });
    },
    async onPageSizeChange() {
      this.loading = true;
      await this.fetchData();
      this.page = 1;
    },
    async fetchData() {
      this.loading = true;
      const { data } = await this.$axios.get(this.endpointURL, {
        params: this.query,
      });

      this.items = data.data.items;
      this.itemsCount = data.data.count;
      if (data.data.totals) {
        this.totals = data.data.totals;
      }

      if (this.csvMapper) {
        this.jsonData = this.csvMapper(this.items);
      } else {
        this.jsonData = this.items;
      }

      this.loading = false;
    },
    // methods for manipulating table data from outside of the component via refs
    prepend(item) {
      this.items.unshift(item);
      this.itemsCount++;
      if (this.items.length > this.pageSize) {
        this.items.pop();
      }
    },
    append(item) {
      this.items.push(item);
      this.itemsCount++;
      if (this.items.length > this.pageSize) {
        this.items.shift();
      }
    },
    set(index, item) {
      this.$set(this.items, index, item);
    },
    refetch() {
      this.refetchData();
    },
  },
  async created() {
    await this.fetchData();
  },
};
</script>

<style lang="less">
.v-data-table__mobile-row {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.data-table {
  margin-top: 32px;

  .title {
    display: flex;
    align-items: flex-end;
    gap: 16px;
    margin-bottom: 16px;

    .left {
      display: flex;
      width: 100%;
      gap: 16px;
      align-items: center;

      h4 {
        margin: 0;
      }

      .financials-h4 {
        // margin-left: 24px;
      }
      .filter {
        .formulate-input {
          margin: 0;
          width: 130px;
        }
      }
      .filter-button {
        border: 1px solid var(--border);
        background-color: var(--content-dark);
        padding: 8px 16px;
        border-radius: 4px;

        .icon {
          margin-right: 8px;
          color: var(--primary);
        }
      }

      @media screen and (max-width: 1200px) {
        flex-direction: column;
        align-items: flex-start;
      }
    }

    .right {
      display: flex;
      width: 320px;
      justify-content: flex-end;

      .csv-download-button {
        border: 1px solid var(--border);
        background-color: var(--content-dark);
        padding: 8px 16px;
        border-radius: 4px;

        .icon {
          margin-right: 8px;
          color: var(--primary);
        }
      }

      .financials-csv {
        margin-right: 24px;
        border: 1px solid var(--border);
        background-color: var(--content-dark);
        padding: 8px 8px;
        border-radius: 4px;

        .icon {
          margin-right: 8px;
          color: var(--primary);
        }
      }
    }
  }

  .content {
    background-color: var(--content-light) !important;
    border-radius: 8px;
    margin-bottom: 64px;

    .top-bar {
      display: flex;
      flex-wrap: wrap;
      padding: 16px 16px 0 16px;
      gap: 14px;
      align-items: center;

      .search {
        width: 100%;
      }

      .formulate-input {
        margin-bottom: 0;
      }

      .show-more {
        display: flex;
        margin-left: auto;
        align-items: center;
        gap: 8px;

        small {
          font-size: 12px;
        }

        @media screen and (max-width: 850px) {
          gap: 0;
          margin: 0 16px;
          text-align: center;
        }
      }
    }

    .v-data-table {
      background-color: var(--content-light) !important;
      color: var(--text) !important;

      & > .v-data-table__wrapper > table > thead > tr > th {
        color: var(--text) !important;
        text-align: left;
        text-transform: uppercase;
      }

      & > .v-data-table__wrapper > table > tbody > tr > td,
      & > .v-data-table__wrapper > table > tfoot > tr > td,
      & > .v-data-table__wrapper > table > thead > tr > td {
        border-bottom: thin solid var(--border) !important;

        .formulate-input {
          margin-bottom: 0;
        }

        &.text-center {
          text-align: center;
        }
      }

      .totals {
        margin: auto;
      }

      &
        > .v-data-table__wrapper
        > table
        > tbody
        > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
        background-color: var(--content-light);
      }

      &.compact {
        & > .v-data-table__wrapper > table > thead > tr > th,
        & > .v-data-table__wrapper > table > tbody > tr > td,
        & > .v-data-table__wrapper > table > tfoot > tr > td,
        & > .v-data-table__wrapper > table > thead > tr > td {
          font-size: 0.7em;
        }
      }

      .v-data-footer {
        padding: 4px 16px;

        &__select {
          display: none;
        }

        &__icons-before {
          margin-left: auto;
        }

        .v-btn {
          color: var(--text) !important;
        }
      }
    }
  }
}
</style>
