<template>
  <TTDialog
    :title="`${selectedFilter.label} Filter`"
    :active="active"
    v-on:close="close"
  >
    <v-app>
      <v-row>
        <v-col class="d-flex" cols="12" sm="6">
          <v-select
            v-if="selectedFilter.type === 'string'"
            :items="stringComparision"
            label="Comparison"
            outlined
            :value="'contains'"
            v-model="filter"
          ></v-select>
          <v-select
            v-if="selectedFilter.type === 'number'"
            :items="numberComparision"
            label="Comparison"
            outlined
            :value="'equals'"
          ></v-select>
        </v-col>
        <v-col class="d-flex" cols="12" sm="6">
          <v-text-field
            label="Value"
            placeholder="value"
            outlined
            v-model="filterValue"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col class="d-flex" cols="12" sm="6">
          <v-btn text @click="dialog = false">
            Cancel
          </v-btn>
        </v-col>
        <v-col class="d-flex" cols="12" sm="6">
          <v-btn color="var(--primary)" @click="addFilter">
            Add Filter
          </v-btn>
        </v-col>
      </v-row>
    </v-app>
  </TTDialog>
</template>

<script>
import TTDialog from "@/components/TTDialog.vue";
import JsonCSV from "vue-json-csv";
import DiyoboButton from "../DiyoboButton.vue";

export default {
  name: "filter-selector",
  components: {
    TTDialog,
    JsonCSV,
    DiyoboButton,
  },
  props: {
    jsonData: Array,
    estimateTime: {
      type: String,
      default: "5 mins",
    },
    noOfEntries: {
      type: String,
      default: "1000",
    },
    title: {
      type: String,
      default: "CSV Filter",
    },
    loading: {
      type: Boolean,
      default: false,
    },
    filterType: {
      type: String,
      default: "string",
    },
    selectedFilter: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      stringComparision: [
        {
          text: "contains",
          value: "like",
        },
        {
          text: "not contains",
          value: "not_like",
        },
        {
          text: "equals",
          value: "=",
        },
        {
          text: "not equals",
          value: "!=",
        },
      ],
      numberComparision: [
        {
          text: "greater than >",
          value: ">",
        },
        {
          text: "less than <",
          value: "<",
        },
        {
          text: "equals",
          value: "=",
        },
        {
          text: "not equals",
          value: "not_equal",
        },
      ],
      active: false,
      collection: null,
      selection: null,
      isClicked: false,

      filter: null,
      filterValue: null,
    };
  },
  methods: {
    addFilter(val) {
      this.$emit("addFilter", {});
    },
    selectCSV(data) {
      this.selection = data;
      this.$emit("download", data);
      if (data === "all") this.isClicked = true;
    },
    open() {
      this.isClicked = false;
      this.selection = null;
      // this.collection = collection;
      // console.log("from modal", collection);

      setTimeout(() => {
        this.active = true;
      }, 250);
    },
    close() {
      this.active = false;
    },
  },
};
</script>
<style scoped>
.v-application {
  padding: initial !important;
  background: none !important;
}
</style>
<style lang="less" scoped>
.detail {
  display: flex;
  flex-direction: column;
}
.formulate-input-wrapper {
  .formulate-input[data-classification="button"] button,
  .formulate-input[data-classification="submit"] button {
    width: 100%;
  }
}
::v-deep button {
  width: 100%;
}
</style>
